<template lang="pug">
	section#meuPerfil
		.invalidRoute(v-if="!isValid")
		.wrapper(v-else)
			div.perfil
				.image
					label(for="foto" v-cloak @drop.prevent="updateImage" @dragover.prevent, ref="labelImagem")
						img(:src="usuario.foto || require('@images/icones/icone-foto.png')", ref="imagePerfil")
					input(type="File" id="foto" @change="updateImage()" ref="inputImage")
				.informacao
					h2 Olá, {{usuario.nome}}
					p {{usuario.email}}
					p {{usuario.cpf}}
					p {{usuario.telefone}}
					p {{usuario.endereco}}, {{usuario.numero}}
					p {{usuario.cidade.nome}} - {{usuario.cidade.estado.uf | uppercase}}
			div.editar
				ul.nav
					li(@click="changeAba('meuPerfil')" :class="{'ativo': abaAberta == 'meuPerfil'}")
						p Meu perfil
					li(@click="changeAba('meusPedidos')" :class="{'ativo': abaAberta == 'meusPedidos'}")
						p Meus pedidos
					li(@click="changeAba('meusPlanos')" :class="{'ativo': abaAberta == 'meusPlanos'}",v-if="usuario.associacoes.length")
						p Associação
					li(@click="changeAba('redefinicao')" :class="{'ativo': abaAberta == 'redefinicao'}")
						p Redefinição de senha
				div.abas
					div.editarPerfil(v-if="abaAberta == 'meuPerfil'")
						form(v-on:submit.prevent="sendAtualizar")
							div.campos
								div.group
									div.input(:class="{error: errors.categoria_id}")
										select(v-model="usuario.categoria_id")
											option(:value="null", disabled) Categoria
											option(:value="categoria.id", v-for="categoria in categorias", v-if="categoria") {{categoria.nome}}
										span(v-if="errors.categoria_id") {{errors.categoria_id[0]}}
							div.campos
								h3 Dados Pessoais
								div.group
									div.input(:class="{error: errors.nome}")
										input(type="text", placeholder="Nome", v-model="usuario.nome")
										span(v-if="errors.nome") {{errors.nome[0]}}
								div.group
									div.input(:class="{error: errors.sobrenome}")
										input(type="text", placeholder="Sobrenome", v-model="usuario.sobrenome")
										span(v-if="errors.sobrenome") {{errors.sobrenome[0]}}
								div.group
									div.input(:class="{error: errors.documento}")
										input(type="tel", :disabled="usuario.conta_atualizada == 1", placeholder="CPF/CNPJ", v-model="usuario.documento", v-mask="['###.###.###-##', '##.###.###/####-##']")
										span(v-if="errors.documento") {{errors.documento[0]}}
									div.input(:class="{error: errors.rg}")
										input(type="text", placeholder="RG", v-model="usuario.rg")
										span(v-if="errors.rg") {{errors.rg[0]}}
								div.group
									div.input(:class="{error: errors.email}")
										input(type="email", disabled, placeholder="E-mail", v-model="usuario.email")
										span(v-if="errors.email") {{errors.email[0]}}
								div.group
									div.input(:class="{error: errors.data_nascimento}")
										input(type="tel", placeholder="Data de Nascimento", v-model="usuario.data_nascimento",v-mask="'##/##/####'")
										span(v-if="errors.data_nascimento") {{errors.data_nascimento[0]}}
									div.input(:class="{error: errors.telefone}")
										input(type="tel", placeholder="Telefone", v-model="usuario.telefone", v-mask="['(##) ####-####', '(##) #####-####']")
										span(v-if="errors.telefone") {{errors.telefone[0]}}
								div.group
									div.input(:class="{error: errors.sexo}")
										select(v-model="usuario.sexo")
											option(:value="null", disabled) Gênero
											option(value="Masculino") Masculino
											option(value="Feminino") Feminino
											option(value="Outros") Outros
											option(value="Prefiro não informar") Prefiro não informar
										span(v-if="errors.sexo") {{errors.sexo[0]}}
									div.input._25(:class="{error: errors.estado_cnpj_id}", v-if="isCnpj")
										select(v-model="usuario.estado_cnpj_id")
											option(:value="null", disabled) Estado de registro CNPJ
											option(:value="estado.id", v-for="estado in estados", v-if="estados") {{estado.nome}}
										span(v-if="errors.estado_cnpj_id") {{errors.estado_cnpj_id[0]}}

							.campos
								h3 Dados de Endereço
								div.group
									div.input._25(:class="{error: errors.cep}")
										input(type="tel", placeholder="CEP", v-model="usuario.cep", v-mask="'##.###-###'", @change="loadEnderecoViaCep()")
										span(v-if="errors.cep") {{errors.cep[0]}}
									div.input._25(:class="{error: errors.estado_id}")
										select(v-model="usuario.cidade.estado.id", @change="loadCidades(usuario.cidade.estado.id)")
											option(:value="null", disabled) Estado
											option(:value="estado.id", v-for="estado in estados", v-if="estados") {{estado.nome}}
										span(v-if="errors.estado_id") {{errors.estado_id[0]}}
								div.group
									div.input._25(:class="{error: errors.cidade_id}")
										select(v-model="usuario.cidade_id")
											option(:value="null", disabled) Cidade
											option(:value="cidade.id", v-for="cidade in cidades", v-if="cidades") {{cidade.nome}}
										span(v-if="errors.cidade_id") {{errors.cidade_id[0]}}
									div.input._25(:class="{error: errors.bairro}")
										input(name="input_bairro", type="text", autocomplete="off", autocorrect="off", placeholder="Bairro" v-model="usuario.bairro")
										span(v-if="errors.bairro") {{errors.bairro[0]}}
								div.group
									div.input(:class="{error: errors.endereco}")
										input(type="text", placeholder="Endereço", v-model="usuario.endereco")
										span(v-if="errors.endereco") {{errors.endereco[0]}}
								div.group
									div.input._25(:class="{error: errors.numero}")
										input(type="tel", placeholder="Numero", v-model="usuario.numero")
										span(v-if="errors.numero") {{errors.numero[0]}}
									div.input._50(:class="{error: errors.complemento}")
										input(type="text", placeholder="Complemento", v-model="usuario.complemento")
										span(v-if="errors.complemento") {{errors.complemento[0]}}
							.campos(v-if="usuario.categoria_id && usuario.categoria_id !== categoria_estudante_id")
								h3 Dados Profissionais
								.group
									div.input._25(:class="{error: errors.profissao_id}")
										select(v-model="usuario.profissao_id", @change="loadConselhos(usuario.profissao_id)")
											option(:value="null", disabled) Profissão
											option(:value="profissao.id", v-for="profissao in profissoes", v-if="profissoes") {{profissao.nome}}
										span(v-if="errors.profissao_id") {{errors.profissao_id[0]}}

									div.input._25(:class="{error: errors.conselho_id}", v-if="usuario.profissao_id !== profissao_outros_id")
										select(v-model="usuario.conselho_id", :disabled="!conselhos.length")
											option(:value="null", disabled) Conselho
											option(:value="conselho.id", v-for="conselho in conselhos", v-if="conselho") {{conselho.nome}}
										span(v-if="errors.conselho_id") {{errors.conselho_id[0]}}

								div.group(v-if="usuario.profissao_id === profissao_outros_id")
									div.input(:class="{error: errors.profissao_texto}")
										input(type="text", placeholder="Profissão", v-model="usuario.profissao_texto")
										span(v-if="errors.profissao_texto") {{errors.profissao_texto[0]}}
									div.input._25(:class="{error: errors.conselho_texto}")
										input(name="input_conselho_texto", type="text", placeholder="Conselho" v-model="usuario.conselho_texto")
										span(v-if="errors.conselho_texto") {{errors.conselho_texto[0]}}

								.group
									div.input._25(:class="{error: errors['informacoes_adicionais.profissional.estado_id']}")
										select(v-model="temp.profissional.estado_id")
											option(:value="null", disabled) Estado
											option(:value="estado.id", v-for="estado in estados", v-if="estados") {{estado.nome}}
										span(v-if="errors['informacoes_adicionais.profissional.estado_id']") {{errors['informacoes_adicionais.profissional.estado_id'][0]}}

									div.input._25(:class="{error: errors.numero_conselho}")
										input(name="input_numero_conselho", type="text", autocomplete="off", autocorrect="off", placeholder="Registro no Conselho" v-model="conselho")
										span(v-if="errors.numero_conselho") {{errors.numero_conselho[0]}}

								.group
									div.input._25(:class="{error: errors.especialidade}")
										input(name="input_especialidade", type="text", autocomplete="off", autocorrect="off", placeholder="Especialidade" v-model="usuario.especialidade")
										span(v-if="errors.especialidade") {{errors.especialidade[0]}}
								.group
									div.input(:class="{error: errors['informacoes_adicionais.profissional.empresa']}")
										input(name="input_empresa_atual", type="text", autocomplete="off", autocorrect="off", placeholder="Nome da Instituição de Trabalho" v-model="usuario.informacoes_adicionais.profissional.empresa")
										span(v-if="errors['informacoes_adicionais.profissional.empresa']") {{errors['informacoes_adicionais.profissional.empresa'][0]}}
								.group
									div.input(:class="{error: errors['informacoes_adicionais.profissional.contato']}")
										input(name="input_empresa_contato", type="text", autocomplete="off", autocorrect="off", placeholder="Pessoa para Contato" v-model="usuario.informacoes_adicionais.profissional.contato")
										span(v-if="errors['informacoes_adicionais.profissional.contato']") {{errors['informacoes_adicionais.profissional.contato'][0]}}



							.campos(v-if="usuario.categoria_id && usuario.categoria_id === categoria_estudante_id")
								h3 Dados Acadêmicos
								div.group
									div.input(:class="{error: errors['informacoes_adicionais.academico.curso']}")
										input(type="text", placeholder="Curso", v-model="usuario.informacoes_adicionais.academico.curso")
										span(v-if="errors['informacoes_adicionais.academico.curso']") {{errors['informacoes_adicionais.academico.curso'][0]}}
								div.group
									div.input.checkbox._050
										input(type="checkbox", id="input_is_estudante_liga" v-model="usuario.is_estudante_liga")
										label(for="input_is_estudante_liga") Sou estudante de Liga Acadêmica
								div.group
									div.input.document(:class="{error: errors['documento_comprovante']}")
										label(for="input_document" v-cloak @drop.prevent="updateDocument($event, 'documento_comprovante')" @dragover.prevent, ref="labelDocument")
											img(:src="require('@images/icones/icone-documento.png')")
											| {{nomeDocumento}}
										input(name="input_document", type="File" id="input_document" @change="updateDocument(null, 'documento_comprovante')" ref="documento_comprovante")
										a(v-if="hasDocumentoAntigo", :href="usuario.documento_comprovante_link", target="_blank")
											span Antigo: {{hasDocumentoAntigo}}
										span(v-if="errors['documento_comprovante']") {{errors['documento_comprovante'][0]}}
								div.group
									div.input.document(v-if="usuario.is_estudante_liga", :class="{error: errors['documento_comprovante_liga']}")
										label(for="input_document_liga" v-cloak @drop.prevent="updateDocument($event, 'documento_comprovante_liga')" @dragover.prevent, ref="labelDocumentLiga")
											img(:src="require('@images/icones/icone-documento.png')")
											| {{nomeDocumentoLiga}}
										input(name="input_document_liga", type="File" id="input_document_liga" @change="updateDocument(null, 'documento_comprovante_liga')" ref="documento_comprovante_liga")
										a(v-if="hasDocumentoLigaAntigo", :href="usuario.documento_comprovante_liga_link", target="_blank")
											span Antigo: {{hasDocumentoLigaAntigo}}
										span(v-if="errors['documento_comprovante_liga']") {{errors['documento_comprovante_liga'][0]}}

							button.registrar(:disabled="sending") Atualizar perfil

							p.message(v-if="message") {{ message }}

					div.pedidos(v-if="abaAberta == 'meusPedidos'")
						div.table
							div.cabecalho
								p.idPedido # Pedido
								p.data Data
								p.valor Valor
								p.status Status
							div.corpo
								div.pedido(v-for="pedido in usuario.pedidos")
									div.resumo
										p.idPedido(data-header="# Pedido") \#{{ pedido.id }}
										p.data(data-header="Data") {{ pedido.registro }}
										p.valor(data-header="Valor") R$ {{ pedido.total | fixed }}
										p.status(data-header="Status", :class="getStatusColour(pedido.last_status.status)") {{ pedido.last_status.texto_status}}
									div.detalhes
										div.itens
											ul
												li(v-for="produto in pedido.produtos")
													span {{ produto.quantidade }}x {{ produto.produto.produto.nome }}
													span.valor R$ {{ produto.produto.preco_total | fixed }}
												li(v-if="pedido.has_associacao && pedido.last_status.status === 'approved'")
													button(:disabled="sending", @click="sendReceiptGenerate(pedido.id)") {{text_botao_associacao}}
						p.message(v-if="message") {{ message }}

					div.associacao(v-if="abaAberta == 'meusPlanos' && usuario.associacoes.length")
						div.plano
							ul
								li(v-for="associacao in usuario.associacoes")
									div
										p.nome
											strong {{ associacao.nome }}
											br
											// | \#{{ associacao.id }}
										p.validade
											| Validade
											br
											| {{ associacao.expiracao }}
										p.status(:class="{ativo: associacao.ativo}") {{ associacao.ativo ? 'Ativo' : 'Inativo' }}
										p(v-if="associacao.ativo").declaracao
											button(:disabled="sending", @click="sendDeclaracaoAssociado()") {{text_botao_declaracao_associacao}}
						.linksuteis(v-if="hasAssociacaoAtiva")
							h3 Links Úteis
							ul.lista
								li
									a(href="https://paliativo.org.br/educacao/biblioteca/", target="_blank") Biblioteca
										img(src="@images/icones/foreign.png")
								li
									a(href="https://paliativo.org.br/capc/", target="_blank") CAPC
										img(src="@images/icones/foreign.png")
								li
									a(href="https://paliativo.org.br/associados/candidatura-criacao-estaduais-ancp/", target="_blank") Candidaturas Estaduais
										img(src="@images/icones/foreign.png")
								li
									a(href="https://paliativo.org.br/comites-area-associado/", target="_blank") Comitês
										img(src="@images/icones/foreign.png")
								li
									a(href="https://paliativo.org.br/vagas-de-emprego/", target="_blank") Oportunidades
										img(src="@images/icones/foreign.png")
								li
									a(href="https://paliativo.org.br/associados/transparencia-da-ancp/", target="_blank") Transparência
										img(src="@images/icones/foreign.png")

					div.redefinicao(v-if="abaAberta == 'redefinicao'")
						form(@submit.prevent="sendRedefinicao")
							div.campos
								h3 Senha atual
								div.group
									div.input(:class="{error: errors.password}")
										input(type="password", placeholder="Senha atual", v-model="redefinicao.password")
										span(v-if="errors.password") {{errors.password[0]}}
							div.campos
								h3 Redefinição
								div.group
									div.input(:class="{error: errors.new_password}")
										input(type="password", placeholder="Nova senha", v-model="redefinicao.new_password")
										span(v-if="errors.new_password") {{errors.new_password[0]}}
								div.group
									div.input(:class="{error: errors.re_new_password}")
										input(type="password", placeholder="Confirme a nova senha", v-model="redefinicao.re_new_password")
										span(v-if="errors.re_new_password") {{errors.re_new_password[0]}}

							button.registrar(:disabled="sending") Atualizar senha

							p.message(v-if="message") {{ message }}

</template>

<script>
	import {mask} from 'vue-the-mask';
	import Axios from 'axios'

	export default {
		directives: {
			mask
		},
		data() {
			return {
				abaAberta: "meuPerfil",
				isValid: false,
				temp: {
					profissional: {
						estado_id: null
					}
				},
				usuario: {
					documento: '',
					numero_conselho: '',
					informacoes_adicionais: {
						profissional: {},
						academico: {}
					}
				},
				message: null,
				redefinicao: {},
				sending: false,
				errors: {},
				estados: [],
				cidades: [],
				profissoes: [],
				conselhos: [],
				categorias: [],
				categoria_estudante_id: null,
				profissao_outros_id: null,
				text_botao_associacao: 'Recibo Associação',
				text_botao_declaracao_associacao: 'Declaração Associação',
				axios:null,
			};
		},
		filters: {
			uppercase(valor) {
				if (!valor)
					return ''
				return valor.toUpperCase();
			},
			capitalize(valor) {
				if (!valor)
					return '';
				return valor.charAt(0).toUpperCase() + valor.slice(1)
			},
			fixed(value) {
				let numero = parseFloat(value).toFixed(2);
				numero = new Number(numero).toLocaleString("pt-BR");
				if (numero.search(',') == -1)
					numero = numero + ",00"
				return numero;
			}
		},
		computed: {
			conselhoPrefix() {
				let prefix = '';
				let conselho = false;
				if (this.profissao_outros_id === this.usuario.profissao_id) {
					if (this.usuario.conselho_texto)
						prefix += this.usuario.conselho_texto
				} else {
					if (this.usuario.conselho_id && (conselho = this.findById(this.conselhos, this.usuario.conselho_id))) {
						prefix += conselho.nome;
					}
					let estado = false;
					if (this.temp.profissional.estado_id && (estado = this.findById(this.estados, this.temp.profissional.estado_id))) {
						if (prefix !== '')
							prefix += '/';
						prefix += estado.uf;
					}
				}
				if (prefix !== '')
					prefix += ' ';
				return prefix;
			},
			conselho: {
				get() {
					if (this.usuario.numero_conselho && this.usuario.numero_conselho.startsWith(this.conselhoPrefix))
						return this.usuario.numero_conselho;
					return this.conselhoPrefix;
				},
				set(value) {
					this.usuario.numero_conselho = value;
				}
			},
			hasDocumentoAntigo() {
				if (this.usuario.documento_comprovante) {
					if (this.usuario.documento_comprovante_media)
						return this.usuario.documento_comprovante_media.file;
				}
				return false;
			},
			nomeDocumento() {
				if (this.usuario.documento_comprovante)
					return this.usuario.documento_comprovante.name;
				else
					return this.usuario.documento_comprovante_media ? this.usuario.documento_comprovante_media.file : 'Declaração de matrícula';

			},
			hasDocumentoLigaAntigo() {
				if (this.usuario.documento_comprovante_liga) {
					if (this.usuario.documento_comprovante_liga_media)
						return this.usuario.documento_comprovante_liga_media.file;
				}
				return false;
			},
			nomeDocumentoLiga() {
				if (this.usuario.documento_comprovante_liga)
					return this.usuario.documento_comprovante_liga.name;
				else
					return this.usuario.documento_comprovante_liga_media ? this.usuario.documento_comprovante_liga_media.file : 'Declaração do Professor da Liga';
			},
			isCnpj() {
				return this.usuario.documento.length > 14 ? true : false;
			},
			hasAssociacaoAtiva() {
				if (this.usuario.documento)
					return this.usuario.associacoes.find(associacao => associacao.ativo) ? true : false
				return false
			},
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				let redirectTo = vm.$route.fullPath.slice(1);
				window.localStorage.setItem('ancp-attemptedRoute', redirectTo);
				if (vm.$route.query.token) {
					vm.$auth.__updateLocal(vm.$route.query.token);
					vm.$axios.get(`api/usuario/logged-user`).then(response => {
						vm.$auth.usuario = response.data;
						vm.$auth.usuario.token = vm.$route.query.token;
						vm.$auth.__updateLocal(vm.$auth.usuario.token);
					});
				}
				vm.$axios
				.get(`api/usuario/perfil`)
				.then(response => {
					vm.usuario = Object.assign({}, vm.usuario, response.data);
					vm.isValid = true;
					vm.loadCidades(response.data.cidade.estado_id);
					if (!vm.usuario.informacoes_adicionais.academico)
						vm.usuario.informacoes_adicionais.academico = {}
					if (vm.usuario.informacoes_adicionais.profissional) {
						vm.temp.profissional.estado_id = vm.usuario.informacoes_adicionais.profissional.estado_id;
					} else {
						vm.usuario.informacoes_adicionais.profissional = {};
					}
					if (vm.usuario.profissao_id) {
						vm.loadConselhos(vm.usuario.profissao_id);
					}
				})
			})
		},
		created(){
			let axiosConfig = {
				crossDomain: true,
				xhrFields: { withCredentials: true }
			};
			this.axios = Axios.create(axiosConfig);
		},
		mounted() {
			this.loadEstados();
			this.loadCategorias();
			this.loadProfissoes();
		},
		methods: {
			loadEnderecoViaCep() {
				let header = Object.assing({}, this.$axios.defaults.headers);
				delete header.authorization;
				let cep = this.usuario.cep.replace('-', '').replace('.', '')
				if (cep.length === 8) {
					this.$axios.get(`https://viacep.com.br/ws/${cep}/json/`,header).then(response => {
						this.usuario.bairro = response.data.bairro;
						let uf = this.estados.find(estado => estado.uf === response.data.uf);
						this.usuario.endereco = response.data.logradouro;
						if(uf){
							this.usuario.estado_id = uf.id;
							this.$axios
							.get(`api/cidades/${this.usuario.estado_id}`)
							.then(response => {
								this.$data['cidades'] = response.data;
								let cidade = this.estados.find(cidade => cidade.nome === response.data.localidade);
								if(cidade)
									this.usuario.estado_id = cidade.id;
							});
						}
					});
				}
			},
			loadEstados() {
				this.$axios
				.get(`api/estados`)
				.then(response => this.estados = response.data)
			},
			loadCidades(estado_id, lista = 'cidades') {
				this.$axios
				.get(`api/cidades/${estado_id}`)
				.then(response => this.$data[lista] = response.data)
			},
			loadCategorias() {
				this.$axios
				.get(`api/categorias`)
				.then(response => {
					this.categorias = response.data.registros;
					this.categoria_estudante_id = response.data.categoria_estudante_id;
				});
			},
			loadProfissoes() {
				this.$axios
				.get(`api/profissoes`)
				.then(response => {
					this.profissoes = response.data;
					for (let i = 0; i < this.profissoes.length; i++) {
						if (this.profissoes[i].nome === "Outros") {
							this.profissao_outros_id = this.profissoes[i].id;
						}
					}
				});
			},
			loadConselhos(profissao_id) {
				this.$axios
				.get(`api/profissoes/${profissao_id}/conselhos`)
				.then(response => this.conselhos = response.data)
			},
			loadEspecialidades() {
				this.$axios
				.get(`api/especializacoes`)
				.then(response => this.especialidades = response.data)
			},
			changeAba(opcao) {
				this.abaAberta = opcao;
				this.message = null;
			},
			updateImage(e = null) {
				var reader = new FileReader();
				this.usuario.foto_perfil_usuario = e ? e.dataTransfer.files[0] : this.$refs.inputImage.files[0];
				reader.onload = image => {
					this.$refs.imagePerfil.src = image.target.result;
				};
				reader.readAsDataURL(
					e ? e.dataTransfer.files[0] : this.$refs.inputImage.files[0]
				);
				let formData = new FormData();
				formData.append('foto_perfil_usuario', this.usuario.foto_perfil_usuario)
				this.$axios.post(`api/usuario/atualizar/foto`, formData)

			},
			updateDocument(e = null, documento = 'documento_comprovante') {
				this.usuario[documento] = e ? e.dataTransfer.files[0] : this.$refs[documento].files[0]
			},
			sendAtualizar() {
				if (this.temp.profissional.estado_id)
					this.usuario.informacoes_adicionais.profissional.estado_id = this.temp.profissional.estado_id;
				let formData = new FormData();
				Object.keys(this.usuario).forEach(item => {
					if (this.usuario[item] != null) {
						if (item == 'informacoes_adicionais') {
							formData.append(item, JSON.stringify(this.usuario[item]))
						} else if (item != 'foto_perfil_usuario') {
							formData.append(item, this.usuario[item])
						}
					}
				})
				this.message = null;
				this.sending = true;
				this.errors = {};
				this.$axios
				.post(`api/usuario/atualizar`, formData)
				.then(response => {
					this.message = response.data.message;
					this.$auth.usuario.conta_atualizada = 1;
				})
				.catch(error => {
					this.sending = false;
					if (error.response.status == 422)
						this.errors = error.response.data.errors;
				})
				.finally(() => {
					setTimeout(() => {
						this.sending = false;
						this.message = null;
					}, 2500)
				});
			},
			sendRedefinicao() {
				this.message = null;
				this.sending = true;
				this.errors = {};
				this.$axios
				.put(`api/password/redefine`, this.redefinicao)
				.then(response => {
					this.message = response.data.message;
					this.redefinicao = {};
					setTimeout(() => {
						this.$auth.logout(() => {
							this.$router.push({name: 'login'})
						});
					}, 2000)
				})
				.catch(error => {
					this.sending = false;
					if (error.response.status > 400) {
						if (error.response.data.errors)
							this.errors = error.response.data.errors;
						else
							this.message = error.response.data.message;
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.sending = false;
						this.message = null;
					}, 2500)
				})
			},
			findById(arrayData, searchId) {
				for (let i = 0; i < arrayData.length; i++) {
					if (arrayData[i] && arrayData[i].id == searchId)
						return arrayData[i];
				}
				return false;
			},
			getStatusColour(status) {
				if (status !== 'approved' && status !== 'pending')
					return 'other';
				return status;
			},
			sendReceiptGenerate(pedido_id) {
				this.sending = true;
				this.text_botao_associacao = 'Enviando';
				this.$axios
				.get(`api/usuario/generated-receipt/` + pedido_id)
				.then(response => {
					this.sending = false;
					this.message = response.data.message;
					this.text_botao_associacao = 'Enviado para o email';
				}).catch(error => {
					this.sending = false;
					this.message = error.response.data.message;
					this.text_botao_associacao = 'Ocorreu um erro';
				})
				.finally(() => {
					setTimeout(() => {
						this.sending = false;
						this.message = null;
						this.text_botao_associacao = 'Recibo Associação';
					}, 3500)
				});
			},
			sendDeclaracaoAssociado() {
				this.sending = true;
				this.text_botao_declaracao_associacao = 'Enviando';
				this.$axios
				.get(`api/usuario/declaracao-associado`)
				.then(response => {
					this.sending = false;
					this.message = response.data.message;
					this.text_botao_declaracao_associacao = 'Enviado para o email';
				}).catch(error => {
					this.sending = false;
					this.message = error.response.data.message;
					this.text_botao_declaracao_associacao = 'Ocorreu um erro';
				})
				.finally(() => {
					setTimeout(() => {
						this.sending = false;
						this.message = null;
						this.text_botao_declaracao_associacao = 'Declaração Associação';
					}, 3500)
				});
			}

		}
	};
</script>

<style lang="stylus" scoped src="./MeuPerfil.styl"></style>
